import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PageContent from '../components/elements/pageContentContainer';

const NotFoundPage = () => (
  <Layout dark>
    <SEO title="404: Not found" />
    <PageContent>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </PageContent>
  </Layout>
);

export default NotFoundPage;
